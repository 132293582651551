import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Tile from './Tile';
import Avatar from './Avatar';
import { Box } from '../Box';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image-components"
    }}>{`Image Components`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#tile"
        }}>{`Tile`}</a>{` Image tile`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#avatar"
        }}>{`Avatar`}</a>{` Basic avatar`}</li>
    </ul>
    <h2 {...{
      "id": "tile"
    }}>{`Tile`}</h2>
    <p>{`This image was specifically designed for the experience cards (3/2 aspect ratio), but can be applied to more aspect ratios now.`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={Tile} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Underneath it uses the `}<a parentName="p" {...{
        "href": "/components/Box"
      }}>{`Box`}</a>{` component.`}</p>
    <p>{`Tile has a`}</p>
    <ul>
      <li parentName="ul">{`Required prop for `}<inlineCode parentName="li">{`src`}</inlineCode>{` that needs the image URL`}</li>
      <li parentName="ul">{`Optional prop `}<inlineCode parentName="li">{`ratio`}</inlineCode>{` that can take `}<inlineCode parentName="li">{`'1/1'`}</inlineCode>{`, `}<inlineCode parentName="li">{`'3/2'`}</inlineCode>{` or `}<inlineCode parentName="li">{`'4/3'`}</inlineCode>{` currently. It defaults to `}<inlineCode parentName="li">{`'1/1'`}</inlineCode></li>
      <li parentName="ul">{`Optional prop `}<inlineCode parentName="li">{`borderRadius`}</inlineCode>{` that defaults to `}<inlineCode parentName="li">{`'3px'`}</inlineCode></li>
    </ul>
    <p>{`The width will adjust to the width of the parent container (100%) and then determine the height accordingly.`}</p>
    <Playground __position={1} __code={'() => {\n  const src =\n    \'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg\'\n  const card =\n    \'https://storage.googleapis.com/hoppin-platform-images/br-5b44c9ad96738038d071ffc9/exp/1569549455741000_card_600x400.jpeg\'\n  return (\n    <Box>\n      <Box width=\"200px\" padding=\"base\" bg=\"primary.lighter\">\n        <Tile src={src} />\n      </Box>\n      <Box\n        width=\"300px\"\n        marginTop=\"large\"\n        padding=\"base\"\n        bg=\"primary.lighter\"\n      >\n        <Tile src={card} ratio=\"3/2\" />\n      </Box>\n      <Box\n        width=\"500px\"\n        marginTop=\"large\"\n        padding=\"base\"\n        bg=\"primary.lighter\"\n      >\n        <Tile src={card} ratio=\"4/3\" borderRadius=\"20px\" />\n      </Box>\n    </Box>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Tile,
      Avatar,
      Box,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const src = 'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg';
        const card = 'https://storage.googleapis.com/hoppin-platform-images/br-5b44c9ad96738038d071ffc9/exp/1569549455741000_card_600x400.jpeg';
        return <Box mdxType="Box">
        <Box width="200px" padding="base" bg="primary.lighter" mdxType="Box">
          <Tile src={src} mdxType="Tile" />
        </Box>
        <Box width="300px" marginTop="large" padding="base" bg="primary.lighter" mdxType="Box">
          <Tile src={card} ratio="3/2" mdxType="Tile" />
        </Box>
        <Box width="500px" marginTop="large" padding="base" bg="primary.lighter" mdxType="Box">
          <Tile src={card} ratio="4/3" borderRadius="20px" mdxType="Tile" />
        </Box>
      </Box>;
      }}
    </Playground>
    <h2 {...{
      "id": "avatar"
    }}>{`Avatar`}</h2>
    <p>{`Component created specifically for rendering an avatar. Underneath it uses the Box and `}<a parentName="p" {...{
        "href": "#tile"
      }}>{`Tile`}</a>{` components`}</p>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <Props of={Avatar} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Underneath it uses the `}<a parentName="p" {...{
        "href": "/components/Box"
      }}>{`Box`}</a>{` component.`}</p>
    <p>{`Avatar has a`}</p>
    <ul>
      <li parentName="ul">{`Required prop for `}<inlineCode parentName="li">{`src`}</inlineCode>{` that needs the image URL`}</li>
      <li parentName="ul">{`Optional prop `}<inlineCode parentName="li">{`size`}</inlineCode>{` that defaults to `}<inlineCode parentName="li">{`'base'`}</inlineCode></li>
      <li parentName="ul">{`Optional prop `}<inlineCode parentName="li">{`squared`}</inlineCode>{` flag that defaults to `}<inlineCode parentName="li">{`false`}</inlineCode></li>
    </ul>
    <Playground __position={3} __code={'() => {\n  const src =\n    \'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg\'\n  return (\n    <Box width=\"400px\" height=\"150px\" padding=\"base\" bg=\"primary.lighter\">\n      <Avatar src={src} paddingRight=\"base\" />\n      <Avatar src={src} paddingRight=\"small\" squared />\n      <Avatar src={src} size=\"icon\" alignSelf=\"flex-start\" />\n    </Box>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Tile,
      Avatar,
      Box,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const src = 'https://storage.googleapis.com/hoppin-platform-avatars/usr-5bd7570e081c0e3b0d036917/1563490774170000_avatar_600x600.jpeg';
        return <Box width="400px" height="150px" padding="base" bg="primary.lighter" mdxType="Box">
        <Avatar src={src} paddingRight="base" mdxType="Avatar" />
        <Avatar src={src} paddingRight="small" squared mdxType="Avatar" />
        <Avatar src={src} size="icon" alignSelf="flex-start" mdxType="Avatar" />
      </Box>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      